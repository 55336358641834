import Gemeentelogo from '@/components/Gemeentelogo.vue';
import {mapActions} from "vuex";

export default {
  components: {
    Gemeentelogo
  },
  data() {
    return this.$store.state.default;
  },
  methods: {
    ...mapActions({
      signOut: 'signOut',
      signIn: 'signIn'
    }),
    bedrag(number) {
      return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(number);
    },
    bereken() {
      if (this.alleVeldenIngevuld) {
        let aanslag = this.setAanslag();
        this.submitted = false;
        let that = this;
        this.maanden = 12;
        this.aInkomens = [];
        let woning = this.woning === '1' ? 'E' : 'H';
        let kinderen = this.kinderen == null ? 0 : this.kinderen;
        let data = {
          aanslag: aanslag,
          bezit: 0,
          bijstand: 0,
          gemcode: this.gemeente.gemcode,
          gezin: this.gezin,
          huur: this.huur,
          woningaanpassing: this.woningaanpassing,
          inkomen: 1000,
          jaarinkomen: 12000,
          kind0: parseInt(this.kind0),
          kind4: parseInt(this.kind4),
          kind12: parseInt(this.kind12),
          kind16: parseInt(this.kind16),
          kinderen: kinderen,
          leeftijd: parseInt(this.leeftijd),
          meetpunten: this.meetpunten,
          partner: parseInt(this.leeftijd_partner),
          schuld: 0,
          woning: woning,
          hypotheekrente: this.hypotheekrente,
          financieringskosten: this.financieringskosten,
          woz: this.woz,
        }
        this.$axios
          .post("api/klant", data)
          .then(response => {
            this.recht = response.data.recht;
            this.inkomenuitbijstand = response.data.klant.inkomenuitbijstand;
            this.meetpunten.forEach((x) => that.aInkomens.push(x * response.data.klant.inkomenuitbijstand / 100)); //inkomenuitbijstand
            this.berekenArmoedeval(kinderen, woning);
          })
          .catch((e) => console.log(e));
      } else {
        this.submitted = true;
      }
    },
    berekenArmoedeval(kinderen, woning) {
      let that = this;
      this.rows = [];
      let graphData = [];
      let zorgtoeslagen = [];
      let huurtoeslagen = [];
      let kindgebondenbudgetten = [];
      this.loonsoort = 'N';
      this.meetpunten.forEach(function (m, i) {
        //tabellen
        let loon = parseInt(that.aInkomens[i]);
        that.loon = loon;
        graphData.push({"x": m, "y": loon, "c": that.labels[0]});
        graphData.push({"x": m, "y": parseInt(that.zorgtoeslag), "c": that.labels[1]});
        zorgtoeslagen.push(parseInt(that.zorgtoeslag));
        if (woning === 'H') {
          huurtoeslagen.push(parseInt(that.huurtoeslag));
          graphData.push({"x": m, "y": parseInt(that.huurtoeslag), "c": that.labels[2]});
        }
        if (kinderen > 0) {
          kindgebondenbudgetten.push(parseInt(that.kindgebondenbudget[1] / 12));
          graphData.push({"x": m, "y": parseInt(that.kindgebondenbudget[1] / 12), "c": that.labels[3]});
        }
        //vega graph
        that.regelingen.forEach(r => {
          if (that.profiel(r.profiel) && r.gebruiken === true && (typeof (r.bedrag) !== 'undefined' || typeof (r.jaarbedrag) !== 'undefined')) {
            let bedrag = r.jaarbedrag > 0 ? r.jaarbedrag / 12 : r.bedrag;
            bedrag = that.recht[i][r.regeling] > 0 ? bedrag : 0;
            graphData.push({"x": m, "y": parseInt(bedrag), "c": r.regeling});
          }
        });
      });
      this.aGraphData = graphData;

      var x = 0;
      while (x < that.aInkomens.length) {
        that.aInkomens[x] = parseInt(that.aInkomens[x]);
        x++
      }
      this.regelingen.forEach(r => {
        //matcht het profiel van de regeling met dat van de klant staat de regeling op gebruiken en is er een bedrag
        if (this.profiel(r.profiel) && r.gebruiken === true && (typeof (r.bedrag) !== 'undefined' || typeof (r.jaarbedrag) !== 'undefined')) {
          let bedrag = r.jaarbedrag > 0 ? r.jaarbedrag / 12 : r.bedrag;
          this.rows.push(this.geefRijToegekendeBedragenVoorDezeRegelingPerMeetpunt(r.regeling, parseInt(bedrag)));
        }
      });
      let inkomens = that.aInkomens.slice();
      inkomens.unshift(this.labels[0]);
      let meetpunten = that.meetpunten.slice();
      meetpunten.unshift('Meetpunten');
      zorgtoeslagen.unshift(this.labels[1]);
      if (kinderen > 0) {
        kindgebondenbudgetten.unshift(this.labels[3]);
        this.rows.push(kindgebondenbudgetten);
      }
      if (woning === 'H') {
        huurtoeslagen.unshift(this.labels[2]);
        this.rows.push(huurtoeslagen);
      }
      this.rows.push(zorgtoeslagen);
      this.rows.push(inkomens);
      this.rows.push(this.geefRijMetTotalen())
      this.rows.push(meetpunten);

      this.armoedeval = true;
    },
    eenVeld(type, i) {
      if (this.regelingen[i][type] !== '') {
        this.regelingen[i][type] = '';
      }
    },
    geefRijToegekendeBedragenVoorDezeRegelingPerMeetpunt(regeling, bedrag) {
      let res = [regeling];
      this.recht.forEach(function (mp) {
        let toegekend = mp[regeling] > 0 ? bedrag : 0;
        res.push(toegekend);
      });
      return res;
    },
    geefRijMetTotalen() {
      const reducer = (accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue);
      const sums = [];
      for (let i = 1; i <= this.meetpunten.length; i++) {
        sums.push(this.rows.map(x => x[i]).reduce(reducer));
      }
      sums.unshift('Totalen');
      return sums;
    },
    haalRegelingenOp() {
      let data = {
        gemcode: this.gemeente.gemcode
      }
      this.$axios
        .post("api/beleidloontregelingen", data)
        .then(response => {
          this.regelingen = response.data;
        })
        .catch(() => alert('kon geen regelingen ophalen'));
    },
    profiel(profiel) {
      if (this.kinderen === 0)
        return !['kinderen', 'studentmetkinderen'].includes(profiel);
      else return true;
    },
    resetHuur() {
      this.huur = '';
    },
    resetKinderen() {
      this.kind0 = 0;
      this.kind4 = 0;
      this.kind12 = 0;
      this.kind16 = 0;
    },
    resetPartner() {
      this.loon_partner = 0;
      this.leeftijd_partner = 0;
    },
    resetWoz() {
      this.woz = 0;
      this.woz_ondergrens = null;
      this.woz_factoren = null;
    },
    setAanslag() {
      let regelingKwijtschelding = this.regelingen.filter(r => r['kwijtschelding'] === 1 && r.gebruiken === true)[0];
      if (regelingKwijtschelding) {
        if (regelingKwijtschelding.bedrag > 0) {
          return parseInt(regelingKwijtschelding.bedrag) * 12;
        }
        if (regelingKwijtschelding.jaarbedrag > 0) {
          return parseInt(regelingKwijtschelding.jaarbedrag);
        }
      }
      return 0;
    },
    syncRegelingenGebruiken(i) {
      if (!this.regelingen[i].gebruiken) {
        delete this.regelingen[i].gebruiken;
        delete this.regelingen[i].bedrag;
        delete this.regelingen[i].jaarbedrag;
      }
    },
    storeMeetpunten(meetpunten) {
      this.meetpunten = meetpunten
    },
    ucfirst(str) {
      return str.replace(/^\w/, c => c.toUpperCase());
    },
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    is_ingelogd() {
      return this.$store.getters.authenticated;
    },
    aftoppingsgrens() {
      return this.huishouden === 'meer' ? this.c.huurtoeslagFactoren['aftoppingsgrenshoog'] : this.c.huurtoeslagFactoren['aftoppingsgrenslaag'];
    },
    aftoppingsgrenspercentage() {
      return this.c.huurtoeslagFactoren['aftoppingsgrenspercentage'];
    },
    alleVeldenIngevuld() {
      return ['22', '23'].includes(this.leeftijd) &&
        ['0', '1'].includes(this.partner) &&
        ['0', '1'].includes(this.showkinderen) &&
        ['0', '1'].includes(this.woning) &&
        this.ingevuldPartnerVelden &&
        this.ingevuldVergoedingen &&
        this.ingevuldWoningVelden &&
        this.voldoendeMeetpunten
    },
    basishuur() {
      return this.c.huurtoeslagFactoren['basishuur'];
    },
    basishuurberekend() {
      let basishuurberekend = this.totaal_toetsingsinkomen <= this.inkomensijkpunt ? this.basishuur : this.normhuur + this.normhuurverhoging;
      return this.afgerond(basishuurberekend);
    },
    eigenwoningforfait() {
      let eigenwoningforfait = 0;
      if (this.woz > '0') {
        this.woz_ondergrens = this.ondergrens(this.woz, this.c.wozWaarden);
        this.woz_factoren = this.c.wozWaarden[this.woz_ondergrens];
        let perc = this.woz_factoren[0] / 100;
        let plus = this.woz_factoren[1];
        eigenwoningforfait = plus === 0 ? Math.round(this.woz * perc) : Math.round(((this.woz - this.woz_ondergrens) * perc) + plus)
      }
      return eigenwoningforfait;
    },
    gezin() {
      return this.partner === '1' ? 'E' : (this.kinderen > 0 ? 'AO' : 'A');
    },
    huishouden() {
      let huishouden = null;
      if (this.gezin === 'A') {
        huishouden = 1;
      }
      if (this.gezin === 'AO') {
        huishouden = this.kinderen === '1' ? 2 : 'meer';
      }
      if (this.gezin === 'E') {
        huishouden = this.kinderen === '0' ? 2 : 'meer';
      }
      return huishouden;
    },
    huurtoeslag() {
      let toeslag = null;
      let toeslag_1 = 0;
      let toeslag_2 = 0;
      let toeslag_3 = 0;

      if (this.huur > '0') {
        //de huur is te laag of te hoog voor huursubsidie
        if ((this.huur <= this.basishuur) || (this.huur >= this.maxhuur)) {
          toeslag = 0;
        } else {
          if (this.huur <= this.basishuurberekend)
            toeslag = 0;
          else {
            if (this.basishuurberekend < this.kwaliteitskortingsgrens) {
              if (this.huur <= this.kwaliteitskortingsgrens) {
                toeslag_1 = this.huur - this.basishuurberekend;
              } else {
                toeslag_1 = this.kwaliteitskortingsgrens - this.basishuurberekend;
              }
            }

            if (this.basishuurberekend < this.aftoppingsgrens) {
              if (this.huur <= this.aftoppingsgrens) {
                toeslag_2 = this.kwaliteitskortingsgrenspercentage / 100 * Math.max((this.huur - this.kwaliteitskortingsgrens), 0);
              } else {
                toeslag_2 = this.kwaliteitskortingsgrenspercentage / 100 * (this.aftoppingsgrens - this.kwaliteitskortingsgrens);
                toeslag_3 = this.aftoppingsgrenspercentage / 100 * (this.huur - this.aftoppingsgrens);
              }
            }
          }
          this.toeslag_ = [toeslag_1, toeslag_2, toeslag_3];
        }
      }
      toeslag = toeslag_1 + toeslag_2 + toeslag_3;
      return this.afgerond(toeslag);
    },
    ingevuldPartnerVelden() {
      if (this.partner === '1') {
        return this.loon_partner >= 0 && this.loonsoorten.includes(this.loonsoort_partner) && ['22', '23'].includes(this.leeftijd_partner)
      } else return true;
    },
    ingevuldVergoedingen() {
      let r = 0;
      this.regelingen.forEach(function (regeling) {
        if (
          regeling.gebruiken &&
          (typeof (regeling.bedrag) === 'undefined' || regeling.bedrag == '' || !regeling.bedrag.match(/\d{1,5}/)) &&
          (typeof (regeling.jaarbedrag) === 'undefined' || regeling.jaarbedrag == '' || !regeling.jaarbedrag.match(/\d{1,5}/))
        ) {
          r++;
        }
      });
      return r == 0;
    },
    ingevuldWoningVelden() {
      return this.woning === '1' && this.hypotheekrente > 0 && this.woz > 0 || this.woning === '0' && this.huur > 0
    },
    inkomensijkpunt() {
      return this.huishouden === 1 ? this.c.huurtoeslagFactoren['inkomensijkpuntlaag'] : this.c.huurtoeslagFactoren['inkomensijkpunthoog'];
    },
    jaarinkomenaanvrager() {
      let jaarinkomenaanvrager =
        parseFloat(this.maandloonFiscaal * this.maanden)
        + parseFloat(this.vakantiegeld)
        + parseFloat(this.eenmalige_uitkering > 0 ? this.eenmalige_uitkering : 0)
        + parseFloat(this.ontvangen_alimentatie > 0 ? this.ontvangen_alimentatie * 12 : 0)
        - parseFloat(this.betaalde_alimentatie > 0 ? this.betaalde_alimentatie * 12 : 0);
      return jaarinkomenaanvrager;
    },
    jaarinkomenpartner() {
      let jaarinkomenpartner =
        +parseFloat(this.maandloonPartnerFiscaal * this.maanden_partner)
        + parseFloat(this.vakantiegeld_partner)
        + parseFloat(this.eenmalige_uitkering_partner > 0 ? this.eenmalige_uitkering_partner : 0)
        + parseFloat(this.ontvangen_alimentatie_partner > 0 ? this.ontvangen_alimentatie_partner * 12 : 0)
        - parseFloat(this.betaalde_alimentatie_partner > 0 ? this.betaalde_alimentatie_partner * 12 : 0);
      return jaarinkomenpartner
    },
    jong() {
      let jong = null;
      if (this.gezin === 'E' && this.leefijd !== '' && this.leeftijd_partner !== '') {
        // met kinderen sowieso niet jong, zonder kinderen als beiden < 22 jr
        jong = this.kinderen > '0' ? 0 : (this.leeftijd === '22' && this.leeftijd_partner === '22' ? 1 : 0);
      }
      if (this.gezin === 'A') {
        jong = this.leeftijd === '22' ? 1 : 0;
      }
      if (this.gezin === 'AO') {
        jong = 0;
      }
      return jong;
    },
    kinderen() {
      return parseInt(this.kind0) + parseInt(this.kind4) + parseInt(this.kind12) + parseInt(this.kind16);
    },
    kindgebondenbudget() {
      let kindgebondenbudget = 0;
      let kindgebondenbudgetbijstand = 0;
      if (this.kinderen > 0) {
        let k = this.c.kindgebondenbudgetFactoren;
        let budgetdeel_1 = 0;
        if (this.kinderen === 1) budgetdeel_1 = k['kind1'];
        if (this.kinderen === 2) budgetdeel_1 = k['kind2'];
        if (this.kinderen === 3) budgetdeel_1 = k['kind3'];
        if (this.kinderen > 3) {
          let verschil = this.kinderen - 3;
          budgetdeel_1 = k['kind3'] + (verschil * k['ophogingmeerkind'])
        }
        let budgetdeel_2 = this.kind12 * k['ophoging12jaar'];
        let budgetdeel_3 = this.kind16 * k['ophoging16jaar'];
        let budgetdeel_4 = this.gezin === 'AO' ? k['ophogingalleenstaande'] : 0;
        let budget = budgetdeel_1 + budgetdeel_2 + budgetdeel_3 + budgetdeel_4;
        let drempelinkomen = this.gezin === 'AO' ? k['drempelinkomenalleenstaande'] : (this.gezin === 'E' ? k['drempelinkomenechtpaar'] : 0);
        let grens = this.totaal_toetsingsinkomen - drempelinkomen;
        let verlaging = grens <= 0 ? 0 : k['verlagingmeerinkomenpercentage'] / 100 * grens;
        kindgebondenbudgetbijstand = budget;
        if (verlaging > budget) {
          verlaging = budget;
        }
        kindgebondenbudget = budget - verlaging;
        this.kindgebondenbudgetdata = [budgetdeel_1, budgetdeel_2, budgetdeel_3, budgetdeel_4, grens, verlaging, k['ophoging12jaar'], k['ophoging16jaar'], k['drempelinkomen'], verlaging];
      }
      return [kindgebondenbudgetbijstand, kindgebondenbudget];
    },
    kwaliteitskortingsgrens() {
      return this.c.huurtoeslagFactoren['kwaliteitskortingsgrens'];
    },
    kwaliteitskortingsgrenspercentage() {
      return this.c.huurtoeslagFactoren['kwaliteitskortingsgrenspercentage'];
    },
    labels() {
      return [
        'Bijstand / loon ex vt per maand',
        'Zorgtoeslag',
        'Huurtoeslag',
        'Kindgebonden budget',
        'Kinderopvang',
        'Kinderopvang kosten', 'Meetpunten'
      ];
    },
    logo() {
      return `https://www.berekenuwrechtplus.nl/logo/${this.gemeente.logo}`;
    },
    maandloonFiscaal() {
      let bruto = this.vindBrutoUitNetto(this.loon, this.loon * 1.5);
      return parseFloat(bruto) + parseFloat(bruto * this.c.toetsingsinkomenFactoren.zvwPercentage / 100);
    },
    maandloonPartnerFiscaal() {
      //1600
      if (this.partner === "0") return 0;
      let bruto = this.vindBrutoUitNetto(this.loon_partner, this.loon_partner * 1.5);
      return parseFloat(bruto) + parseFloat(bruto * this.c.toetsingsinkomenFactoren.zvwPercentage / 100);
    },
    maxhuur() {
      return this.jong === 1 ? this.c.huurtoeslagFactoren['maxhuurjongeren'] : this.c.huurtoeslagFactoren['maxhuur'];
    },
    normhuur() {
      let normhuur = null;
      if (this.totaal_toetsingsinkomen > this.inkomensijkpunt) {
        let factorA = this.huishouden == '1' ? this.c.huurtoeslagFactoren['factor_a_een'] : this.c.huurtoeslagFactoren['factor_a_meer'];
        let factorB = this.huishouden == '1' ? this.c.huurtoeslagFactoren['factor_b_een'] : this.c.huurtoeslagFactoren['factor_b_meer'];
        normhuur = (factorA * this.totaal_toetsingsinkomen * this.totaal_toetsingsinkomen) + (factorB * this.totaal_toetsingsinkomen);
        normhuur = this.afgerond(normhuur);
      }
      return normhuur;
    },
    normhuurverhoging() {
      return this.c.huurtoeslagFactoren['normhuurverhoging'];
    },
    toetsingsinkomen() {
      let toetsingsinkomen = 0
      if (this.jaarinkomenaanvrager > 0) {
        if (this.gezin === 'E') {
          toetsingsinkomen = this.jaarinkomenaanvrager
          if (this.woning === '1') {
            toetsingsinkomen = toetsingsinkomen
              - (parseInt((this.hypotheekrente * 12) - this.financieringskosten) / 2)
              + (parseInt(this.eigenwoningforfait) / 2)
          }
        } else {
          toetsingsinkomen = this.jaarinkomenaanvrager
          if (this.woning === '1') {
            toetsingsinkomen = toetsingsinkomen
              - parseInt(this.hypotheekrente * 12) - parseInt(this.financieringskosten)
              + this.eigenwoningforfait;
          }
        }
      }
      return toetsingsinkomen;
    },
    toetsingsinkomen_partner() {
      let toetsingsjaarinkomenpartner = 0;
      if (this.jaarinkomenpartner > 0) {
        toetsingsjaarinkomenpartner = this.jaarinkomenpartner
        if (this.woning === '1') {
          toetsingsjaarinkomenpartner = toetsingsjaarinkomenpartner
            - ((parseInt(this.hypotheekrente * 12) - parseInt(this.financieringskosten)) / 2)
            + (parseInt(this.eigenwoningforfait) / 2);
        }
      }
      return toetsingsjaarinkomenpartner;
    },
    totaal_toetsingsinkomen() {
      return parseFloat(this.toetsingsinkomen) + parseFloat(this.toetsingsinkomen_partner);
    },
    uurpercentagehoog() {
      return this.c.kinderopvangFactoren['uurpercentagehoog'];
    },
    uurpercentagelaag() {
      return this.c.kinderopvangFactoren['uurpercentagelaag'];
    },
    vakantiegeld() {
      return this.afgerond(this.maandloonFiscaal * 12 * 8 / 100);
    },
    vakantiegeld_partner() {
      return this.afgerond(this.maandloonPartnerFiscaal * 12 * 8 / 100);
    },
    volwassenen() {
      return this.gezin === 'E' ? 2 : 1;
    },
    voldoendeMeetpunten() {
      const kleinerDanHonderd = (i) => i < 100;
      return this.meetpunten.length > 1 && !this.meetpunten.some(kleinerDanHonderd)
    },
    warning() {
      return this.submitted ? !this.alleVeldenIngevuld : false;
    },
    zorgtoeslag() {
      let zorgtoeslag = null;
      if (this.toetsingsinkomen > 0 && this.partner !== '') {
        let kolom = this.gezin === 'E' ? 1 : 0;
        this.zt_ondergrens = this.ondergrens((this.totaal_toetsingsinkomen), this.c.zorgtoeslagWaarden);
        zorgtoeslag = this.c.zorgtoeslagWaarden[this.zt_ondergrens][kolom];
      }
      return zorgtoeslag;
    },
  },
  watch: {
    is_ingelogd: function () {
      this.haalRegelingenOp();
    },
  }
}