export default {

  huurtoeslagFactoren: {
    "aftoppingsgrenshoog": 731.93,
    "aftoppingsgrenslaag": 682.96,
    "aftoppingsgrenspercentage": 40,
    "basishuur": 202.05,
    "factor_a_een": 0.000000438698,
    "factor_a_meer": 0.000000298184,
    "factor_b_een": 0.000446393,
    "factor_b_meer": -0.0013825,
    "inkomensijkpunthoog": 30450,
    "inkomensijkpuntlaag": 22700,
    "kwaliteitskortingsgrens": 477.2,
    "kwaliteitskortingsgrenspercentage": 65,
    "maxhuur": 900.07,
    "maxhuurjongeren": 477.2,
    "normhuurverhoging": -37.14
  },

  kindgebondenbudgetFactoren: {
    'drempelinkomen': 28406,
    'drempelinkomenalleenstaande': 28406,
    'drempelinkomenechtpaar': 37545,
    'kind1': 2511,
    'kind2': 5022,
    'kind3': 7533,
    'ophoging12jaar': 703,
    'ophoging16jaar': 936,
    'ophogingalleenstaande': 3389,
    'ophogingmeerkind': 2511,
    'verlagingmeerinkomenpercentage': 7.1
  },

  toetsingsinkomenFactoren: {
    'AHKbedrag': 3068,
    'AHKmax': 76817,
    'AHKmin': 28406,
    'AHKpercentage': 6.337,
    'percentagehoog': 37.48,
    'percentagelaag': 35.82,
    'zvwPercentage': 6.51
  },

  arbeidskortingWaarden: {
    0: [0, 8.053],
    12169: [980, 30.03],
    26288: [5599, 2.471],
    43071: [5599, 6.51],
    129078: [0, 0]
  },
  kinderopvangWaarden: {
    0: [96.00, 96.00],
    45701: [96, 96],
    47404: [95.3, 95.6],
    49109: [94.6, 95.2],
    50812: [93.7, 94.8],
    52520: [93.1, 94.5],
    54222: [92.3, 94.5],
    55926: [91.6, 94.5],
    57630: [90.7, 94.5],
    59493: [89.2, 94.5],
    63145: [88.4, 94.1],
    66795: [87.3, 93.5],
    70447: [85, 93.1],
    74101: [82.7, 92.8],
    77751: [80.5, 92.1],
    81405: [78, 91.6],
    85056: [75.7, 91.1],
    88708: [73.5, 90.4],
    92361: [71.1, 89.8],
    96011: [68.9, 89.4],
    99668: [66.4, 89.1],
    103319: [64.1, 88.4],
    106969: [61.9, 88],
    110622: [59.5, 87.5],
    114345: [57.4, 86.8],
    118087: [55.3, 86.3],
    121826: [53.2, 85.9],
    125566: [51, 85.6],
    129304: [49.1, 84.9],
    133046: [47.2, 84.3],
    136787: [45.3, 83.9],
    140529: [43.3, 83.3],
    144265: [41.3, 82.9],
    148004: [39.3, 82.2],
    151747: [37.3, 81.6],
    155485: [35.3, 80.6],
    159225: [33.3, 80.3],
    162964: [33.3, 79.5],
    166706: [33.3, 78.6],
    170450: [33.3, 78],
    174187: [33.3, 77.1],
    177927: [33.3, 76.6],
    181664: [33.3, 75.8],
    185407: [33.3, 75.1],
    189148: [33.3, 74.4],
    192889: [33.3, 73.4],
    196628: [33.3, 72.9],
    200364: [33.3, 72.2],
    204108: [33.3, 71.4],
    207846: [33.3, 70.7],
    211587: [33.3, 70.1],
    215328: [33.3, 69.3],
    219066: [33.3, 68.5],
    222807: [33.3, 68],
    226546: [33.3, 67.1]
  },

  wozWaarden: {
    0: [0, 0],
    12500: [0.10, 0],
    25000: [0.20, 0],
    50000: [0.25, 0],
    75000: [0.35, 0],
    1320000: [2.35, 4620]
  },

  zorgtoeslagWaarden: {
    0: [131, 250],
    28000: [131, 250],
    29000: [124, 244],
    30000: [112, 232],
    31000: [101, 221],
    32000: [90, 209],
    33000: [78, 198],
    34000: [67, 187],
    35000: [55, 175],
    36000: [44, 164],
    37000: [33, 152],
    38000: [21, 141],
    39000: [10, 129],
    40000: [0, 118],
    41000: [0, 107],
    42000: [0, 95],
    43000: [0, 84],
    44000: [0, 72],
    45000: [0, 61],
    46000: [0, 50],
    47000: [0, 38],
    48000: [0, 27],
    49000: [0, 15],
    50000: [0, 4],
    50206: [0, 0]
  },}