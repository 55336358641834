import Vue from 'vue';
import axios from 'axios';

/**
 * Axios
 */


const apiHost = function(){
  // Development
  if(['bur.test','burplus.test','dev.berekenuwrechtplus.nl','www.burplus.nl','acceptatie.burplus.nl', 'burplus.nl'].includes(location.host) ){
    console.log(`Connected to: dev environment ${location.host}`);
    return location.protocol+'//'+location.host;
  }
  // Productie
  return process.env.VUE_APP_URL;
}


  const XdebugParameter = (process.env.NODE_ENV !== 'production')?'PHPSTORM':'';


if (XdebugParameter && document.cookie.indexOf('XDEBUG') !== -1) { // Cookie moet bestaan, Gebruik hiervoor Xdebug Helper Chrome Extension
  axios.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['XDEBUG_SESSION_START'] = XdebugParameter;
    return config;
  });
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = apiHost();

Vue.prototype.$axios = axios;